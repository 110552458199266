import { createAuth0 } from '@auth0/auth0-vue'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const auth0 = createAuth0({
    domain: config.public.domain,
    clientId: config.public.client_id,
    authorizationParams: {
      redirect_uri: `${config.public.auth0_redirect}/callback`,
    },
  })
  const client = import.meta.client
  if (client) {
    nuxtApp.vueApp.use(auth0)
  }
})
