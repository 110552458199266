import { defineAsyncComponent } from 'vue'
export default {
  "cgbank-contact": defineAsyncComponent(() => import("/app/layouts/cgbank/contact.vue").then(m => m.default || m)),
  "cgbank-default": defineAsyncComponent(() => import("/app/layouts/cgbank/default.vue").then(m => m.default || m)),
  "cgbank-footer": defineAsyncComponent(() => import("/app/layouts/cgbank/footer.vue").then(m => m.default || m)),
  "cgbank-header": defineAsyncComponent(() => import("/app/layouts/cgbank/header.vue").then(m => m.default || m)),
  "cgbank-lp": defineAsyncComponent(() => import("/app/layouts/cgbank/lp.vue").then(m => m.default || m)),
  "cgbank-navs": defineAsyncComponent(() => import("/app/layouts/cgbank/navs.vue").then(m => m.default || m)),
  "cgbank-scroll-top-btn": defineAsyncComponent(() => import("/app/layouts/cgbank/scroll-top-btn.vue").then(m => m.default || m)),
  "cgbank-tag-search": defineAsyncComponent(() => import("/app/layouts/cgbank/tag-search.vue").then(m => m.default || m)),
  dashboard: defineAsyncComponent(() => import("/app/layouts/dashboard.vue").then(m => m.default || m)),
  default: defineAsyncComponent(() => import("/app/layouts/default.vue").then(m => m.default || m)),
  lp: defineAsyncComponent(() => import("/app/layouts/lp.vue").then(m => m.default || m))
}