import { defineNuxtPlugin } from '#app/nuxt'
import { LazyLoraCsvUpload, LazyGenerateHistory, LazyUsageStatus, LazyUsersDownload, LazyArtifactSearch, LazyArtifactDialog, LazyArtifactImage, LazyCbBtn, LazyCbContainer, LazyCheckboxTags, LazyDirectusUploadCsv, LazyHashtag, LazyIconMarker, LazyIconNonePrice, LazyPrice, LazySignUpDialog, LazyTagList, LazyTag, LazyGenerateContainer, LazyGeneratedImageBar, LazyHistoryBar, LazyCgbankNavigateCard, LazyHistory, LazyGeneratedImage, LazyImageTagList, LazyProgress, LazySlideBeforeAfter, LazyInputBar, LazyAccessibility, LazyFreeWordInput, LazyFreeWord, LazyGenerateButton, LazyLineDetailSelector, LazyPullDownWithImage, LazySelectorTitle, LazySimplePullDown, LazyStepContainer, LazyStepTooltip, LazyStructureTypeSelector, LazyUploadImage, LazyContainer, LazyFooter, LazyAlertArea, LazyButton, LazyCatchSection, LazyContactSection, LazyDownloadFlowSection, LazyStepOne, LazyStepThree, LazyStepTwo, LazyTriangle, LazyBaseFaq, LazyFaqSection, LazyCard, LazyEasy, LazyPerspective, LazyRealtime, LazyReform, LazyTool, LazyFeatureSectionV2, LazyFeatureSection, LazyHamburgerMenu, LazyHeroSection, LazyLpHeader, LazyPriceSection, LazyRenderingExamplesSection, LazySolutionSection, LazyVideoSection, LazyArtifactDeleteButton, LazyArtifactDetail, LazyArtifactRemoveDialog, LazyCgbankLogo, LazyDialog, LazyDownload, LazySubscriptionChange, LazyDownloadButton, LazyHeadMeta, LazyHeader, LazyImage, LazyMyRendererLogo, LazyNavigations, LazyPopup, LazySubscription, LazyUser, LazyTabContainer } from '#components'
const lazyGlobalComponents = [
  ["LoraCsvUpload", LazyLoraCsvUpload],
["GenerateHistory", LazyGenerateHistory],
["UsageStatus", LazyUsageStatus],
["UsersDownload", LazyUsersDownload],
["ArtifactSearch", LazyArtifactSearch],
["ArtifactDialog", LazyArtifactDialog],
["ArtifactImage", LazyArtifactImage],
["CbBtn", LazyCbBtn],
["CbContainer", LazyCbContainer],
["CheckboxTags", LazyCheckboxTags],
["DirectusUploadCsv", LazyDirectusUploadCsv],
["Hashtag", LazyHashtag],
["IconMarker", LazyIconMarker],
["IconNonePrice", LazyIconNonePrice],
["Price", LazyPrice],
["SignUpDialog", LazySignUpDialog],
["TagList", LazyTagList],
["Tag", LazyTag],
["GenerateContainer", LazyGenerateContainer],
["GeneratedImageBar", LazyGeneratedImageBar],
["HistoryBar", LazyHistoryBar],
["CgbankNavigateCard", LazyCgbankNavigateCard],
["History", LazyHistory],
["GeneratedImage", LazyGeneratedImage],
["ImageTagList", LazyImageTagList],
["Progress", LazyProgress],
["SlideBeforeAfter", LazySlideBeforeAfter],
["InputBar", LazyInputBar],
["Accessibility", LazyAccessibility],
["FreeWordInput", LazyFreeWordInput],
["FreeWord", LazyFreeWord],
["GenerateButton", LazyGenerateButton],
["LineDetailSelector", LazyLineDetailSelector],
["PullDownWithImage", LazyPullDownWithImage],
["SelectorTitle", LazySelectorTitle],
["SimplePullDown", LazySimplePullDown],
["StepContainer", LazyStepContainer],
["StepTooltip", LazyStepTooltip],
["StructureTypeSelector", LazyStructureTypeSelector],
["UploadImage", LazyUploadImage],
["Container", LazyContainer],
["Footer", LazyFooter],
["AlertArea", LazyAlertArea],
["Button", LazyButton],
["CatchSection", LazyCatchSection],
["ContactSection", LazyContactSection],
["DownloadFlowSection", LazyDownloadFlowSection],
["StepOne", LazyStepOne],
["StepThree", LazyStepThree],
["StepTwo", LazyStepTwo],
["Triangle", LazyTriangle],
["BaseFaq", LazyBaseFaq],
["FaqSection", LazyFaqSection],
["Card", LazyCard],
["Easy", LazyEasy],
["Perspective", LazyPerspective],
["Realtime", LazyRealtime],
["Reform", LazyReform],
["Tool", LazyTool],
["FeatureSectionV2", LazyFeatureSectionV2],
["FeatureSection", LazyFeatureSection],
["HamburgerMenu", LazyHamburgerMenu],
["HeroSection", LazyHeroSection],
["LpHeader", LazyLpHeader],
["PriceSection", LazyPriceSection],
["RenderingExamplesSection", LazyRenderingExamplesSection],
["SolutionSection", LazySolutionSection],
["VideoSection", LazyVideoSection],
["ArtifactDeleteButton", LazyArtifactDeleteButton],
["ArtifactDetail", LazyArtifactDetail],
["ArtifactRemoveDialog", LazyArtifactRemoveDialog],
["CgbankLogo", LazyCgbankLogo],
["Dialog", LazyDialog],
["Download", LazyDownload],
["SubscriptionChange", LazySubscriptionChange],
["DownloadButton", LazyDownloadButton],
["HeadMeta", LazyHeadMeta],
["Header", LazyHeader],
["Image", LazyImage],
["MyRendererLogo", LazyMyRendererLogo],
["Navigations", LazyNavigations],
["Popup", LazyPopup],
["Subscription", LazySubscription],
["User", LazyUser],
["TabContainer", LazyTabContainer],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
