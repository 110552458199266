import revive_payload_client_IBcIXHBqWF from "/app/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@planetscale+database@1.19.0_@types+node@22.10.2_db0@0.2.1__5vlivqlchtvwgrv3eql4ttbd44/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_0Nb8J0RV0y from "/app/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@planetscale+database@1.19.0_@types+node@22.10.2_db0@0.2.1__5vlivqlchtvwgrv3eql4ttbd44/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_NfgU3q4RrG from "/app/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@planetscale+database@1.19.0_@types+node@22.10.2_db0@0.2.1__5vlivqlchtvwgrv3eql4ttbd44/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_9s6TML0kzV from "/app/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@planetscale+database@1.19.0_@types+node@22.10.2_db0@0.2.1__5vlivqlchtvwgrv3eql4ttbd44/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_tGsnx1vqme from "/app/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@planetscale+database@1.19.0_@types+node@22.10.2_db0@0.2.1__5vlivqlchtvwgrv3eql4ttbd44/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_gUpfXJ7zPi from "/app/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@planetscale+database@1.19.0_@types+node@22.10.2_db0@0.2.1__5vlivqlchtvwgrv3eql4ttbd44/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_b7FxnAHbvu from "/app/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@planetscale+database@1.19.0_@types+node@22.10.2_db0@0.2.1__5vlivqlchtvwgrv3eql4ttbd44/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_8oCExFjJTY from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.29.1_typescript@5.7.2_vue@3.5.13_typescript@5.7.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_06QBznFtjM from "/app/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@planetscale+database@1.19.0_@types+node@22.10.2_db0@0.2.1__5vlivqlchtvwgrv3eql4ttbd44/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_eCZq4ph7UB from "/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.15.0_@parcel+watcher@2.5.0_@planetscal_c7otrcl6a2rziubgqguks7zyd4/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_6SgOCU1D4Y from "/app/node_modules/.pnpm/nuxt-papa-parse@1.0.8_magicast@0.3.5_rollup@4.29.1/node_modules/nuxt-papa-parse/dist/runtime/plugin.mjs";
import plugin_QpwGmIm4UL from "/app/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.29.1_typesc_icz4ul6ywmtdjxvqpabl34m57a/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import apexchart_gUgipXIQGn from "/app/plugins/apexchart.ts";
import auth_vT9JWWT9pN from "/app/plugins/auth.ts";
import click_outside_directive_YjNOGbcjIP from "/app/plugins/click-outside-directive.ts";
import custom_fetch_UFdPayo4xQ from "/app/plugins/custom-fetch.ts";
import sentry_3AyO8nEfhE from "/app/plugins/sentry.ts";
import vuetify_7h9QAQEssH from "/app/plugins/vuetify.ts";
export default [
  revive_payload_client_IBcIXHBqWF,
  unhead_0Nb8J0RV0y,
  router_NfgU3q4RrG,
  payload_client_9s6TML0kzV,
  navigation_repaint_client_tGsnx1vqme,
  check_outdated_build_client_gUpfXJ7zPi,
  chunk_reload_client_b7FxnAHbvu,
  plugin_vue3_8oCExFjJTY,
  components_plugin_KR1HBZs4kY,
  prefetch_client_06QBznFtjM,
  plugin_eCZq4ph7UB,
  plugin_6SgOCU1D4Y,
  plugin_QpwGmIm4UL,
  apexchart_gUgipXIQGn,
  auth_vT9JWWT9pN,
  click_outside_directive_YjNOGbcjIP,
  custom_fetch_UFdPayo4xQ,
  sentry_3AyO8nEfhE,
  vuetify_7h9QAQEssH
]