export function useMediaQuery(maxWidth: number): { matches: Ref<boolean> } {
  const matches = ref(false)

  let mediaQuery: MediaQueryList

  onMounted(() => {
    mediaQuery = window.matchMedia(`(max-width: ${maxWidth}px)`)

    const handler = (event: any) => {
      matches.value = event.matches
    }

    mediaQuery.addEventListener('change', handler)

    handler(mediaQuery)

    onBeforeUnmount(() => {
      mediaQuery.removeEventListener('change', handler)
    })
  })

  return { matches }
}
