export default defineNuxtPlugin(() => {
  const customFetch = $fetch
    .create({
      async onResponseError({ response }) {
        if (response.status === 401) {
          const { setPopup } = usePopup()
          setPopup('セッションの有効期限が切れたのでログアウトします。', 'red')

          setTimeout(() => {
            window.location.href = '/logout'
          }, 2000)
        }
      },
    })
  return {
    provide: {
      customFetch,
    },
  }
})
