<template>
  <div class="error-view">
    <div class="error-content">
      <h2 class="code">
        {{ props.error?.statusCode }}
      </h2>
      <h3 class="message">
        {{ errorMessage }}
      </h3>
    </div>
    <button
      class="btn"
      @click="handleError"
    >
      トップに戻る
    </button>
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app'

const props = defineProps({
  error: Object as () => NuxtError,
})

let errorMessage = ''
switch (props.error?.statusCode) {
  case 401:
    errorMessage = 'セッションの有効期限が切れたので再度ログインしてください。'
    break
  case 404:
    errorMessage = 'リクエストされたページが見つかりませんでした。'
    break
  case 500:
    errorMessage = '内部サーバーのエラーの為、処理を完了することができませんでした。'
    break

  default:
    errorMessage = 'エラーの為、処理を完了することができませんでした。'
    break
}

const handleError = () => clearError({ redirect: '/generate' })
</script>

<style lang="scss" scoped>
.error-view {
  color: $white;
  gap: 2rem;
  width: 100dvw;
  height: 100dvh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  // background-color: #212836;
  background-image: linear-gradient(360deg, rgba(33, 40, 54, 1), rgba(51, 104, 138, 1));

  > .error-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    > .code {
      font-size: 8rem;
      font-weight: 700;
    }

    > .message {
      font-size: 2rem;
      font-weight: 500;
    }
  }

  > .btn {
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    border: 1px solid $white;
  }
}
</style>
