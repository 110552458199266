import type { ICbTag } from '~/interfaces'

export const useSearchStore = defineStore('searchStore', () => {
  const selectedTagArray = ref<ICbTag[]>([])
  const searchInputs = ref<string>()

  const addSelectedTag = (tag: ICbTag): void => {
    selectedTagArray.value?.push(tag)
  }

  const createSelectedTags = (tagsString: string | null, searchInputString: string | null,
  ): void => {
    const tagsName = tagsString ? tagsString.split(',') : []
    const newSelectedTagArray = createTagsObject(tagsName)
    newSelectedTagArray.then((result) => {
      selectedTagArray.value = result
    }).catch((error) => {
      console.error('Error fetching tags:', error)
    })
    // selectedTagArray.value = createTagsObject(tags)

    searchInputs.value = searchInputString ? searchInputString.split('%20').join(' ') : undefined
  }

  const addSearchInputs = (selectDescText: string) => {
    if (searchInputs.value !== undefined) {
      searchInputs.value += ` ${selectDescText}`
    } else {
      searchInputs.value = selectDescText
    }
  }

  const removeSelectedTag = (tag: ICbTag): void => {
    selectedTagArray.value = selectedTagArray.value?.filter((item) => {
      return item.id !== tag.id
    })
  }

  const clearSelectedTags = (): void => {
    selectedTagArray.value = []
  }

  const splitInputBySpace = (input: string) => {
    const unifiedSpaces = input.replace(/\u3000| |, /g, '%20')
    return unifiedSpaces.split(' ')
  }

  const generateUrl = computed(() => {
    let base = ''
    base += '?'
    if (selectedTagArray.value.length > 0) {
      const tagsName = selectedTagArray.value.map(tag => tag.name)
      const tagParams = tagsName.join(',')
      base += `q=${tagParams}`
    }
    if (searchInputs.value) {
      const splitWords = splitInputBySpace(searchInputs.value).join(',')
      base += `&des=${splitWords}`
    }
    return base
  })

  return {
    generateUrl,
    selectedTagArray,
    searchInputs,
    addSelectedTag,
    removeSelectedTag,
    clearSelectedTags,
    createSelectedTags,
    addSearchInputs,
  }
})
