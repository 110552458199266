<template>
  <header class="header">
    <h1 class="logo">
      <NuxtImg
        class="image"
        to="/generate"
        provider="uploadcare"
        src="108071a7-d311-4bf8-adb2-765deffdf2d9"
        alt="MyRenderer Logo"
      />
    </h1>
  </header>
</template>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: fit-content;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > .logo {
    gap: 0.5rem;
    height: 2rem;
    font-size: 1rem;
    width: fit-content;
    display: flex;
    align-items: center;

    @include media(sm) {
      font-size: 0.685rem;
    }

    > .image {
      width: auto;
      height: 1.5rem;

      @include media(sm) {
        height: 0.685rem;
      }
    }
  }
}
</style>
